import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CouponIcon, ShoppingBagDefault } from "../../../../../assets/Icons";
import {
  BadgeContainer,
  Badge as OldBadge,
} from "../../../../../components/Badge/Badge";
import { Price } from "../../../../../components/Price";
import {
  BuyNowButtonDisplay,
  Image,
  ProductBasicInfoFragment,
  ProductType,
  ProductVariant,
} from "../../../../../generated/graphql";
import { Link } from "../../../../../lib/i18n";
import { H3 } from "../../../../../shared/globals";
import type { ProductCardProps } from "../../../../types";
import Alert from "../../../../../shared/globals/UiElements/Alert";
import { useStore } from "../../../../../lib/storeData";
import ProductCardReviews from "../../ReviewsAndRatings/ProductCardReviews";
import ItemImg from "../../../../../shared/globals/UiElements/ItemImg";
import Flex from "../../../../../shared/globals/UiElements/Flex";
import { getTranslatedDiscountTitle } from "../../../../../shared/utils/getTranslatedDiscountTitle";
import { Badge } from "../../../../../shared/globals/UiElements/Badge";
import { getLocaleInfo } from "../../../../../lib/i18n/locales-data";
import MotionElementWrapper from "../../../../../shared/globals/MotionElementWrapper";
import { PriceBase } from "../../../../../components/Price/Price";
import { theme } from "../../../../../../tailwind.config";
import BuyNowButton from "../../BuyNowButton/BuyNowButton";
import Button from "../../Button/Button";
import useBreakPoints from "../../../../../shared/utils/useBreakPoints";
import WhileInViewAnimation from "../../../../../shared/globals/WhileInViewAnimation";

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  collection,
  isAlertOpen,
  setIsAlertOpen,
  handleAddToCart,
  isDiscount,
  discountedCustomProductPrice,
  availableQuantity,
  isAddingSimpleItem,
  isCartUpdating,
  isProductList = false,
  appliedAutomaticDiscounts,
}) => {
  const { areReviewsActivated, appearance } = useStore();
  const { locale } = useIntl();
  const activeLocale = getLocaleInfo(locale).code;
  const { isTablet } = useBreakPoints();
  const firstVariant = product?.variants?.nodes[0];
  const isCustomProduct = product.type === ProductType.Custom;
  const productHasOptions =
    !isCustomProduct && !!firstVariant?.selectedOptions?.length;
  const isButNowButtonEnabled =
    !!appearance?.productDisplay?.buyNowButton?.display &&
    appearance?.productDisplay?.buyNowButton?.display !==
      BuyNowButtonDisplay.None;
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <WhileInViewAnimation isDisabled={!isProductList} className="bg-inherit">
      <Flex
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        column
        fullWidth
        data-test="product-card"
        className={`relative rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out ${
          isProductList ? "bg-white" : "bg-inherit"
        }`}
        spacing="none"
      >
        <Link
          href={`/product/${collection || "all"}/${product.handle}`}
          fullWidth
        >
          <BadgeContainer>
            <OldBadge
              outOfStock={!product.isInStock}
              onSale={
                !!firstVariant?.compareAtPrice &&
                firstVariant?.price?.amount !== 0
              }
              customInStock={isCustomProduct && !!product.isInStock}
            />
            {isDiscount && (
              <OldBadge customItemDiscount={product?.discount ?? undefined} />
            )}
            {!!appliedAutomaticDiscounts?.length && (
              <Flex flexWrap="wrap" spacing="xs">
                {appliedAutomaticDiscounts.map((discount) => (
                  <Badge
                    key={discount?.id}
                    type="ink"
                    label={
                      <Flex alignItems="center" spacing="xs">
                        <CouponIcon />
                        {getTranslatedDiscountTitle({
                          defaultTitle: discount?.title,
                          currentLocale: activeLocale,
                          translations: discount?.translations,
                        })}
                      </Flex>
                    }
                  />
                ))}
              </Flex>
            )}
          </BadgeContainer>
          <ProductCardImages images={product?.images} isHovered={isHovered} />
        </Link>
        {appearance?.productDisplay?.buyNowButton?.display ===
          BuyNowButtonDisplay.OnHover ||
        appearance?.productDisplay?.buyNowButton?.display ===
          BuyNowButtonDisplay.None ? (
          <div className="relative max-md:hidden z-TOOLTIP ">
            <div
              className={`absolute -bottom-12  px-sm ${
                isCustomProduct ||
                productHasOptions ||
                appearance.productDisplay.buyNowButton.display ===
                  BuyNowButtonDisplay.None
                  ? "left-[50%] transform -translate-x-1/2"
                  : "left-0 right-0"
              }`}
            >
              <MotionElementWrapper
                animate={isHovered ? { y: -60 } : { y: 0 }}
                duration={0.3}
              >
                <CardButtons
                  addToCartOnClick={handleAddToCart}
                  collection={collection}
                  isCustomProduct={isCustomProduct}
                  isCartLimitsEnabled={!!firstVariant?.cartLimitsEnabled}
                  isMinPerCartNotEqualOne={firstVariant?.minPerCart !== 1}
                  hasOptions={productHasOptions}
                  isCartUpdating={isCartUpdating}
                  isInStock={product.isInStock}
                  isAddingSimpleItem={isAddingSimpleItem}
                  product={product}
                />
              </MotionElementWrapper>
            </div>
          </div>
        ) : null}
        <Flex
          column
          className="relative p-sm bg-inherit z-TOOLTIP box-border"
          fullWidth
        >
          <Flex column spacing="s" fullWidth fullHeight>
            <ProductTitle product={product} collection={collection} />
            {areReviewsActivated && (
              <ProductCardReviews
                averageRating={product?.reviewsStatistics?.average}
                totalReviews={product?.reviewsStatistics?.total}
                noMarginTop
              />
            )}
            <ProductPrice
              firstVariant={firstVariant as ProductVariant}
              product={product}
              isCustomProduct={isCustomProduct}
              isDiscount={isDiscount}
              discountedCustomProductPrice={discountedCustomProductPrice}
            />
          </Flex>
          {(appearance?.productDisplay?.buyNowButton?.display !==
            BuyNowButtonDisplay.OnHover &&
            isButNowButtonEnabled) ||
          isTablet ? (
            <CardButtons
              addToCartOnClick={handleAddToCart}
              collection={collection}
              isCustomProduct={isCustomProduct}
              isCartLimitsEnabled={!!firstVariant?.cartLimitsEnabled}
              isMinPerCartNotEqualOne={firstVariant?.minPerCart !== 1}
              hasOptions={productHasOptions}
              isCartUpdating={isCartUpdating}
              isAddingSimpleItem={isAddingSimpleItem}
              isFixed
              isInStock={product.isInStock}
              product={product}
            />
          ) : null}
          {isAlertOpen && (
            <Alert
              message={
                availableQuantity?.type === "stock" ? (
                  <FormattedMessage defaultMessage="Quantity exceeded" />
                ) : (
                  <FormattedMessage
                    defaultMessage="You can only add up to {max} of this product to cart"
                    values={{ max: availableQuantity?.max }}
                  />
                )
              }
              closeAction={() => setIsAlertOpen!(false)}
            />
          )}
        </Flex>
      </Flex>
    </WhileInViewAnimation>
  );
};

export default ProductCard;

interface ProductCardImagesProps {
  images: (Image | null)[] | null;
  isHovered: boolean | undefined;
}

const ProductCardImages = ({ images, isHovered }: ProductCardImagesProps) => {
  if (!images?.length)
    return <ItemImg imgDetails={null} onlyImg objectFit="cover" />;
  return (
    <div className="relative w-full aspect-square overflow-hidden">
      <MotionElementWrapper
        animate={
          isHovered
            ? images.length > 1
              ? { opacity: 0 }
              : { scale: 1.1, opacity: 1 }
            : { opacity: 1 }
        }
        duration={images.length > 1 ? 0.2 : 1}
        className="absolute w-full aspect-square z-TOOLTIP"
      >
        <ItemImg imgDetails={images?.[0]} onlyImg objectFit="cover" />
      </MotionElementWrapper>

      {images.length > 1 && (
        <MotionElementWrapper
          animate={isHovered ? { scale: 1.1 } : { opacity: 1 }}
          duration={1}
          ease="easeOut"
          className="absolute w-full aspect-square "
        >
          <ItemImg imgDetails={images?.[1] || null} onlyImg objectFit="cover" />
        </MotionElementWrapper>
      )}
    </div>
  );
};

interface ProductTitleProps {
  product: ProductBasicInfoFragment;
  collection: string | undefined;
}

const ProductTitle = ({ product, collection }: ProductTitleProps) => (
  <Link
    href={`/product/${collection || "all"}/${product.handle}`}
    className="min-h-10"
  >
    <span
      className="text-gray-800 font-semibold leading-5"
      data-test="text-product-title"
      style={{
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
      }}
    >
      {product?.title}
    </span>
  </Link>
);

interface ProductPriceProps {
  isCustomProduct: boolean;
  isDiscount: boolean | undefined;
  product: ProductBasicInfoFragment;
  discountedCustomProductPrice: number | undefined;
  firstVariant: ProductVariant | undefined;
}

const ProductPrice = ({
  isCustomProduct,
  isDiscount,
  product,
  discountedCustomProductPrice,
  firstVariant,
}: ProductPriceProps) => {
  const { currency: currencyCode } = useStore();
  if (isCustomProduct)
    if (isDiscount)
      return (
        <Flex column spacing="none">
          <Flex spacing="xs" alignItems="end">
            <span className="line-through text-sm text-gray-500">
              <PriceBase money={product?.initialPrice} />
            </span>
            <Price
              money={{
                amount: discountedCustomProductPrice!,
                currencyCode,
              }}
              color={theme.extend.colors.gray[800]}
              fontWeight={600}
            />
          </Flex>
        </Flex>
      );
    else
      return (
        <Flex>
          <Price
            money={product?.initialPrice}
            color={theme.extend.colors.gray[800]}
            fontWeight={600}
          />
        </Flex>
      );
  else
    return (
      <Flex
        flexWrap="wrap"
        horizontalSpacing="xs"
        verticalSpacing="none"
        alignItems="flex-end"
      >
        {firstVariant?.compareAtPrice && (
          <span className="line-through text-sm text-gray-500">
            <PriceBase money={firstVariant?.compareAtPrice} />
          </span>
        )}
        {firstVariant?.price.amount === 0 ? (
          <H3 fontWeight={600} className="text-gray-800">
            <FormattedMessage defaultMessage="Free" />
          </H3>
        ) : (
          <Price
            money={firstVariant?.price}
            color={theme.extend.colors.gray[800]}
            fontWeight={600}
            alignSelf="start"
          />
        )}
      </Flex>
    );
};

interface CardButtonsProps {
  hasOptions?: boolean;
  isCustomProduct?: boolean;
  isInStock?: boolean;
  product?: ProductBasicInfoFragment;
  collection?: string | undefined;
  isCartLimitsEnabled?: boolean | undefined;
  isMinPerCartNotEqualOne?: boolean | undefined;
  isFixed?: boolean;
  isCartUpdating?: boolean;
  isAddingSimpleItem?: boolean;
  addToCartOnClick?: () => void;
}

const CardButtons = ({
  hasOptions,
  isCustomProduct,
  isInStock,
  product,
  collection,
  isCartLimitsEnabled,
  isMinPerCartNotEqualOne,
  isFixed,
  isCartUpdating,
  isAddingSimpleItem,
  addToCartOnClick,
}: CardButtonsProps) => {
  const { appearance } = useStore();
  const { isTablet } = useBreakPoints();

  if (hasOptions)
    return (
      <Button
        to={`/product/${collection || "all"}/${product?.handle}`}
        type="secondary"
        fullWidth={isFixed}
        fontSize={isTablet ? 14 : 16}
      >
        <FormattedMessage defaultMessage="Select options" />
      </Button>
    );

  if (isCustomProduct)
    return (
      <Button
        to={`/product/${collection || "all"}/${product?.handle}`}
        type="secondary"
        fullWidth={isFixed}
        fontSize={isTablet ? 14 : 16}
      >
        <FormattedMessage defaultMessage="Build your product" />
      </Button>
    );

  if (
    appearance?.productDisplay?.buyNowButton?.display !==
    BuyNowButtonDisplay.None
  )
    return (
      <Flex spacing="s">
        <BuyNowButton
          isDisabled={
            !isInStock ||
            (isCartLimitsEnabled && isMinPerCartNotEqualOne) ||
            isCartUpdating
          }
          product={product!}
        />
        <Button
          onlyIcon
          prefixIcon={<ShoppingBagDefault />}
          onClick={addToCartOnClick}
          type="secondary"
          isDisabled={!isInStock || isCartUpdating}
          isLoading={isAddingSimpleItem}
          loadingSpinnerColor={theme.extend.colors.gray[800]}
          fontSize={isTablet ? 14 : 16}
        />
      </Flex>
    );

  return (
    <Button
      type="secondary"
      fullWidth={isFixed}
      isLoading={isAddingSimpleItem}
      onClick={addToCartOnClick}
      prefixIcon={<ShoppingBagDefault />}
      loadingSpinnerColor={theme.extend.colors.gray[800]}
      fontSize={isTablet ? 14 : 16}
      isDisabled={!isInStock}
    >
      <FormattedMessage defaultMessage="Add to cart" />
    </Button>
  );
};
